import React from "react";
import Button from "@material-ui/core/Button";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
    return (
      <div className="App">
        <br />
        <div className="container">
          {/* <br/> */}
          {/* <h1>Oops!... Something went wrong!</h1> */}
          <br />
          <img src={require("./assets/illustration.jpg")} alt="something went wrong"></img>
          <br /> <br />
          
          <div className="text-center">
          <Button
            size="small"
            variant="contained"
            color="primary"
            href="/homepage"
            
          >
            Go Back
          </Button>
          </div>
        </div>
      </div>
    );
    }
    return this.props.children;
  }
}
