import axios from 'axios'

var baseUrl = process.env.REACT_APP_baseUrl
// var baseUrl = require('./env').url





export const listColleges = async token =>{
    console.log("API: listColleges called")
   try {
       var response =  await axios.get(baseUrl + `college?token=${token}`)
    return response
   } catch (error) {
       throw error
   }
}

export const listCourses = async (token,collegeId) =>{
   try {
       return await axios.get(baseUrl + "course",{
        params:{
           token,
           collegeId
        }
    })
   } catch (error) {
       throw error
   }
}

export const listSubjects = async (token,id) =>{
   try {
       return await axios.get(baseUrl + "subject",{
        params:{
           token,
           id
        }
    })
   } catch (error) {
       throw error
   }
}

export const registerStudent = async (token, data) => {
    // var date =data[0].birthday
    function getFormattedDate(d) {
        var day = d.getDate()
        var month = d.getMonth() + 1
        var year = d.getFullYear()
        return `${day}/${month}/${year}`
    }

    var payload = {}

    payload.token = token
    payload.firstName = data[0].firstName
    var lastNameStudent =''
    if(data[0].lastName) lastNameStudent =data[0].lastName
    if(data[0].lastName) payload.lastName = data[0].lastName
    payload.fullName = data[0].lastName? data[0].firstName:data[0].firstName + " " + lastNameStudent
    payload.primaryEmail = data[0].email
    payload.mobile = data[0].countryCode + data[0].mobile
    if(data[0].gender) payload.gender = data[0].gender
    if(data[0].birthday) payload.birthdate =  getFormattedDate(data[0].birthday) 
    if(data[0].country )payload.country = data[0].country
    if(data[0].state) payload.stateProvince = data[0].state
    if( data[0].city) payload.city = data[0].city
    if(data[0].zip) payload.postalCode = data[0].zip
    if(data[0].imgBase) payload.imgBase = data[0].imgBase
    payload.collegeId = data[1].college._id
    payload.courseId = data[1].course._id

    try {
        return await axios.post(baseUrl + "student",payload)  
    } catch (error) {
        console.log("error is ",error,"error data is",error.data);
        alert("Some Network Error.. Please Try Again later")
        throw error
    }
}


export const registerInstructor = async (token, data) => {

    function getFormattedDate(d) {
        var day = d.getDate()
        var month = d.getMonth() + 1
        var year = d.getFullYear()
        return `${day}/${month}/${year}`
    }
    //sanities subject
    data[1].subjects.map(subject => {
        subject.id = subject._id
        subject.courseId = subject.course_Id;
        delete subject.subjectName
        delete subject.description
        delete subject.logoThumbnailPath
        delete subject._id
        delete subject.course_Id
        return subject
    })

    var payload = {}
    payload.token = token
    payload.firstName = data[0].firstName

    var lastNameInst =''
    if(data[0].lastName) lastNameInst = data[0].lastName
    if(data[0].lastName) payload.lastName = data[0].lastName
    payload.fullName = data[0].firstName + " " + lastNameInst
    if(data[0].birthday) payload.birthdate = getFormattedDate(data[0].birthday)

  

    payload.gender = data[0].gender
    payload.mobile = data[0].countryCode + data[0].mobile
    payload.primaryEmail = data[0].email
    payload.country = data[0].country
    payload.stateProvince = data[0].state
    payload.city = data[0].city
    payload.postalCode = data[0].zip
    payload.imgBase = data[0].imgBase
    payload.collegeId = data[1].college._id
    // payload.courseId = data[1].course._id 

    payload.qualification= data[1].qualification
    payload.subjects= data[1].subjects

    console.log("token is",{ token, data, payload })

    try {
        console.log("payload is",payload)
         return await axios.post(baseUrl + "educator",payload)

    } catch (error) {
        console.log("err data",error.response)
        console.log(error.response.status);
        console.log(error.response.headers);
        alert("Some Network Error.. Please Try Again later")
        throw error
    }
}


export const registerCollege = async (token, data) => {

    var coursesForm = []
    if(data[0].course){
        var tempCourse = data[0].course;
        for(let i=0;i<tempCourse.length;i++){
            var tempObj = {
                "id":tempCourse[i]._id,
                "priority": i + 1
            }
            coursesForm.push(tempObj)
        }
    }

    var dateStr ='';
    if(data[1].birthday){
        var d = data[1].birthday
        var day = d.getDate()
        var month = d.getMonth() + 1
        var year = d.getFullYear()
        dateStr = day+'-'+month+'-'+year
    }

    var payload = {
        college:{},
        admin:{}
    }

    payload.college.displayName = data[0].college.displayName
    if(data[0].email) payload.college.contactEmail = data[0].email 
    payload.college.contactPhone = data[0].mobile 
    payload.college.city = data[0].city 
    payload.college.stateProvince = data[0].state
    if(data[0].zip) payload.college.postalCode = data[0].zip
    payload.college.country = data[0].country
    if(data[0].course) payload.college.courses = coursesForm

    // admin
    payload.admin.firstName = data[1].firstName
    if(data[1].lastName) payload.admin.lastName = data[1].lastName
    var lastNameAdmin = ''
    if(data[1].lastName) lastNameAdmin =data[1].lastName
    payload.admin.fullName = data[1].firstName +' '+lastNameAdmin
     if(data[1].birthday) payload.admin.birthdate = dateStr
     if(data[1].gender) payload.admin.gender = data[1].gender 
    payload.admin.mobile = data[1].mobile 
    payload.admin.primaryEmail = data[1].email
    if(data[1].city)  payload.admin.city = data[1].city 
    if(data[1].country) payload.admin.country = data[1].country 
    if(data[1].zip) payload.admin.postalCode = data[1].zip 
    if(data[1].state) payload.admin.stateProviance = data[1].state 
    payload.admin.collegeId =  data[0].college._id 
    if(data[1].imgBase) payload.admin.userThumbnailPath= data[1].imgBase 
    


    try {
        console.log("baseurl is",baseUrl)
        console.log("payload is",payload)
        var response =  await axios.post(baseUrl + `college?token=${token}`,payload)
        console.log("response is",response)
    return response 

    } catch (error) {
        alert("Some Network Error.. Please Try Again later")
        console.log("error response is ", error.response)
        throw error
    }
}

