import React, { useReducer } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";


//checkbox
var Checkbox = ({ fnClick, fnChange, title = "", checked = false }) => (
  <label>
    <input
      onClick={(e) => {
        if (fnClick !== undefined) fnClick(e.target.checked);
      }}
      onChange={(e) => {

        if (fnChange !== undefined) fnChange(e.target.checked);
      }}
      type="checkbox"
      checked={checked}
    />
    {
      " By Accepting this form you agree with the storage and handling of your data by this website in accordance with our privacy policy "
    }
  </label>
);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },

  rootPicture: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  roott: {
    background: "linear-gradient(90deg, #A0D0E3 0%, #DBE5E9 100%)",
    border: 1,
    borderRadius: 1,
    color: "black",
    height: 25,
    width: 275,
    padding: "0 30px",
  },
  container: {
    boxShadow: "0 3px 5px 2px rgba(0, 0, 135, .3)",
    padding: "0 30px",
    borderRadius: 10,

  },
}));

export default function Review(props) {
  var coursesListHere = [],joinedSubjects;

  if (props.value[0].course) {
    var tempCourses = props.value[0].course;
    for (let i = 0; i < tempCourses.length; i++) {
      console.log(tempCourses[i].courseName);
      coursesListHere[i] =  tempCourses[i].courseName;
    }
    console.log('courses list here',coursesListHere)
    console.log('type is', typeof coursesListHere)

    joinedSubjects = coursesListHere.join()
    console.log('value is',joinedSubjects)
  }

  console.log("final props", props);
  var bdayString = "N/A";

  if (props.value[1].birthday) {
    var Dated = new Date(props.value[1].birthday);
    var pmonth = Dated.getMonth();
    var cmonth = pmonth + 1;
    var str = Dated.getDate() + "-" + cmonth + "-" + Dated.getFullYear();
    bdayString = str;
  }

  var params = new URL(document.location).searchParams;
  var redirect_Id = params.get("redirect");
  var image = "";

  if (props.value[1].imgBase) {

    image = props.value[1].imgBase;
  }

  const classes = useStyles();
  const initialState = {
    click: false,

  };

  const reducer = (state, action) => ({ ...state, ...action });
  const [state, setState] = useReducer(reducer, initialState);

  console.log("stateclick", state.click);
  if (state.click) {
    props.onValidAcademic(redirect_Id);
  } else if (!state.click) {
    props.notValid() 
  }

  return (
    <React.Fragment>
      <div>
        <Backdrop className={classes.backdrop} open={props.loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Typography component="div" variant="subtitle1" align={"center"}>
          <Box className={classes.roott} p={1} mx="auto">
            College Details
          </Box>
        </Typography>
      </div>

      <Container>
        <div className={classes.container}>
          <div className={classes.rootPicture} style={{ textAlign: "center" }}>
            <Avatar alt="Display pic" src={image} />
          </div>
          <br />
          <div className="row">
            <div className="col">
              <Typography align="left" gutterBottom>
                <b>College Name</b>
              </Typography>
            </div>
            <div className="col">
              <Typography>
                {" "}
                {props.value[0].college.displayName || "N/A"}{" "}
              </Typography>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Typography align="left" gutterBottom>
                <b>Course Name</b>
              </Typography>
            </div>
            <div className="col">
              <Typography>
                {" "}
                {props.value[0].course ? joinedSubjects : ""}{" "}
              </Typography>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Typography align="left" gutterBottom>
                <b>College Email</b>
              </Typography>
            </div>
            <div className="col">
              <Typography> {props.value[0].email || "N/A"} </Typography>

            </div>
          </div>
          {/* email */}
          <div className="row">
            <div className="col">
              <Typography align="left" gutterBottom>
                <b>Mobile</b>
              </Typography>
            </div>
            <div className="col">
              <Typography> {props.value[0].mobile || "N/A"} </Typography>

            </div>
          </div>
          {/* mobile */}
          <div className="row">
            <div className="col">
              <Typography align="left" gutterBottom>
                <b>City</b>
              </Typography>
            </div>
            <div className="col">
              <Typography> {props.value[0].city || "N/A"} </Typography>

            </div>
          </div>
          {/* city */}
          <div className="row">
            <div className="col">
              <Typography align="left" gutterBottom>
                <b>State</b>
              </Typography>
            </div>
            <div className="col">
              <Typography> {props.value[0].state || "N/A"} </Typography>

    
            </div>
          </div>
          {/* state */}
          <div className="row">
            <div className="col">
              <Typography align="left" gutterBottom>
                <b>Country</b>
              </Typography>
            </div>
            <div className="col">
              <Typography> {props.value[0].country || "N/A"} </Typography>

            </div>
          </div>
          {/* country */}
          <div className="row">
            <div className="col">
              <Typography align="left" gutterBottom>
                <b>Postal Code</b>
              </Typography>
            </div>
            <div className="col">
              <Typography> {props.value[0].zip || "N/A"} </Typography>
            </div>
          </div>
          {/* zip */}
        </div>

      </Container>
      <br />
      <br />

      <Typography component="div" variant="subtitle1" align={"center"}>
        <Box className={classes.roott} p={1} mx="auto">
          Admin Details
        </Box>
      </Typography>
      <Container>
        {/* style={{ backgroundColor: '#c0dcdd'}} */}
        <div className={classes.container}>
          <br />
          <div className="row">
            <div className="col">
              <Typography align="left" gutterBottom>
                <b>Admin Email</b>
              </Typography>
            </div>
            <div className="col">
              <Typography> {props.value[1].email || "N/A"} </Typography>

            </div>
          </div>
          <div className="row">
            <div className="col">
              <Typography align="left" gutterBottom>
                <b>Admin Name</b>
              </Typography>
            </div>
            <div className="col">
              <Typography> {props.value[1].firstName || "N/A"} </Typography>

    
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Typography align="left" gutterBottom>
                <b>Admin Mobile</b>
              </Typography>
            </div>
            <div className="col">
              <Typography> {props.value[1].mobile || "N/A"} </Typography>


              {/* <Typography> {props.value[1].subjects.map(subject => subject.subjectName).join(', ')}   </Typography> */}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Typography align="left" gutterBottom>
                <b>DOB</b>
              </Typography>

            </div>
            <div className="col">
              <Typography> {bdayString} </Typography>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Typography align="left" gutterBottom>
                <b>Gender</b>
              </Typography>
            </div>
            <div className="col">
              <Typography> {props.value[1].gender || "N/A"} </Typography>

            </div>
          </div>

          <div className="row">
            <div className="col">
              <Typography align="left" gutterBottom>
                <b>State</b>
              </Typography>
            </div>
            <div className="col">
              <Typography> {props.value[1].state || "N/A"} </Typography>

            </div>
          </div>

          <br />
        </div>

      </Container>

      <br />
      <br />
      <br />
      <br />

      <Typography component="div" variant="subtitle1" align={"center"}>
        <Box className={classes.roott} p={1} mx="auto">
          Acknowledgment

        </Box>

      </Typography>

      <br />

      <Grid>
        <Checkbox
          title="Clicks"
          fnClick={(v) => setState({ click: v })}
          checked={state.click}
        />
      </Grid>
    </React.Fragment>
  );
}

