import React from "react";
import ReactDOM from "react-dom";
import './assets/main.css';
import App from './server'
import { BrowserRouter } from 'react-router-dom';
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";



ReactDOM.render(
  <React.Fragment>
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_recaptcha_site_key}> 
    {/* <GoogleReCaptchaProvider reCaptchaKey={key}>  */}

      <BrowserRouter>
        <App />
      </BrowserRouter>
    </GoogleReCaptchaProvider>
  </React.Fragment>,
  document.getElementById('root')
);

