import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import AddressForm from './studenRegistration/AddressForm';
import AcademicForm from './studenRegistration/AcademicForm';
import Review from './studenRegistration/Review';
import { registerStudent } from './services/Api'
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import ErrorBoundary from './ErrorBoundary'

var pjson = require('./../package.json');
var version = pjson.version

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.zlipp.in">
        Zlipp LMS
      </Link>{' '}
      {new Date().getFullYear()} {"version :"} {version}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    offset: theme.mixins.toolbar,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    borderRadius: 20, background: 'linear-gradient(90deg, #000000 0%, #474747 100%)',
    height: 40,
    width: 100,
    color: 'white'
  }, buttonN: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    borderRadius: 20,
    background: 'linear-gradient(91deg, #E47D30 0%, #E9B48D 100%)',
    height: 40,
    width: 100,
    color: 'white'
  }
}));


export default function App() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [values, setValues] = useState({})
  const [isDisabled, setIsDisabled] = useState(true)
  const [loading, setLoading] = React.useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const steps = ['Personal Details', 'Academic Details', 'Review Details'];

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <AddressForm onValid={onValid} data={values[0]} notValid={notValid} />;
      case 1:
        return <AcademicForm onValid={onValid} value={values} notValid={notValid} />;
      case 2:
        return <Review onValid={onValid} notValid={notValid} value={values} loading={loading} />;
      default:
        throw new Error('Unknown step');
    }
  }


  function notValid() {
    setIsDisabled(true)
  }

  function onValid(data, step) {
    if (Object.keys(data).length) {
      setValues({ ...values, [step]: data })
    }
    setIsDisabled(false)
  }

  const handleNext = () => {
    console.log("activeStep from handle", activeStep, steps.length - 1)
    if (activeStep === steps.length - 1) {
      setLoading(true)
      return executeRecaptcha("register_student")
        .then(token => {
          return registerStudent(token, values)
        })
        .then(res => {
          // alert("succefully registered")
          console.log(res)

          var { status } = res
          switch (status) {
            case 200:
            case 201:
            case 202: {
              setActiveStep(activeStep + 1)
              break
            }
            case 400: {
              console.log("handle 400 error")
              break
            }
            case 401: {
              console.log("handle 401 error")
              break
            }
            case 500: {
              console.log("handle 500 error")
              break
            }
            default: {
              console.log("handle unknow error")
            }
          }
          setLoading(false)
        })
        .catch(err => console.log(err))
    } else {
      setActiveStep(activeStep + 1);
      setIsDisabled(true)
      console.log("activeStep", activeStep)
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    setIsDisabled(false)
  };
 


  return (
    <ErrorBoundary>
    <React.Fragment>
      <CssBaseline />
      <AppBar position="fixed" color="default" className={classes.appBar}>
        <Toolbar>

          <Typography variant="h6" color="inherit" noWrap>
            Zlipp LMS
          </Typography>
        </Toolbar>
      </AppBar>

      <br/><br/>


      <main className={classes.layout}>
      <br/><br/>
        <Paper className={classes.paper}>
          {/* <br /><br /> */}

          <Typography component="h4" variant="h5" align="center">
            Student Registration Form
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>

            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" align="center">
                  Thank you for Registering
                </Typography>
                <Button></Button>
              </React.Fragment>
            ) : (
                <React.Fragment>
                  <br/>
                 

                  {getStepContent(activeStep)}


                  <div style={{padding:"2px"}}>
                  <br/>
                  
                    <Typography >
                      Disclaimar: Fields with(*) are mandatory.
                    </Typography>
                  </div>


                  <div className={classes.buttons}>
                 
                  <br/>
                  <br/>

                    {activeStep !== 0 && (
                      <Button onClick={handleBack} className={classes.button}>
                        Back
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isDisabled}
                      onClick={handleNext}
                      className={classes.buttonN}
                    >
                      {activeStep === steps.length - 1 ? 'Register' : 'Next >>'}
                    </Button>
                  </div>
                </React.Fragment>
              )}
          </React.Fragment>
        </Paper>

        <Copyright />
      </main>
    </React.Fragment>
  </ErrorBoundary>
  );
}


