import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { listColleges, listCourses } from "../services/Api";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

// import ErrorBoundary from '../ErrorBoundary'
var base64 = require("base-64");

const Joi = require("joi");

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  root1: {
    background: "linear-gradient(90deg, #A0D0E3 0%, #DBE5E9 100%)",
    border: 5,
    borderRadius: 5,
    color: "black",
    height: 25,
    width: 275,
    padding: "0 30px",
  },
  container: {
    boxShadow: "0 3px 5px 2px rgba(0, 0, 135, .3)",
    padding: "0 30px",
    borderRadius: 10,
  },
}));

var selectedCollege;

const Schema = Joi.object({
  college: Joi.object().required(),
  course: Joi.object().required(),
});

export default function AcademicForm(props) {
  console.log("props value is", props.value);
  console.log("props value 1 type is", typeof props.value[1]);

  const classes = useStyles();
  var [values, setValues] = useState({});
  var [errors, setErrors] = useState({});
  const [loading, setLoading] = React.useState(false);
  var [SelectedCollege, setSelectedCollege] = useState({});
  var [colleges, setColleges] = useState([]);
  var [courses, setCourses] = useState([]);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleChange = (event) => {
    console.log(event.target);
    switch (event.target.name) {
      case "college": {
        console.log("collge id now selected is", event.target.value);
        selectedCollege = colleges.find(
          (element) => element._id === event.target.value
        );
        console.log("from case college selected college", selectedCollege);
        setSelectedCollege(selectedCollege);
        console.log({ college: SelectedCollege, values });
     console.log("use effect selected college is", selectedCollege);
    var { error, value } = Schema.validate(
      { college: selectedCollege },
      values
    );
    if (error) {
      setErrors({
        [error.details[0].path[0]]:
          error.details[0].message || "enter valid information",
      }); 
    } else {
      setErrors({});
      props.onValid(value, 1);
    }
    console.log("valuesss are", value);
    setValues(value);
    props.notValid();

        console.log("values here is", values);
        console.log(errors);
        break;
      }
      case "course": {
        const selectedCourse = courses.find(
          (element) => element._id === event.target.value
        );
        console.log('selectedCourse is ',selectedCourse)
        var tempCourse = {
          college:values.college,
          course:selectedCourse
        }
        console.log('temp course is',tempCourse)
        const { error, value } = Schema.validate(tempCourse);
        console.log('course value is ',value)
        if (error) {
          setErrors({
            [error.details[0].path[0]]:
              error.details[0].message || "enter valid information",
          }); 
        } else {
          setErrors({});
          props.onValid(value, 1);
        }
        setValues(value);
        console.log(errors);
        break;
      }
      default: {
        console.log("handle default");
      }
    }
  };


  useEffect(() => {
    setValues(props.value[1] || {});
    var { error, value } = Schema.validate(props.value[1]);
    if (!error) {
    }
    if (props.value[1]) props.onValid(value || {}, 1);
    getColleges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.college]);


  var response;
  const getColleges = async () => {
    try {

      console.log("listing colleges");
      setLoading(true);
      var token = await executeRecaptcha("list_colleges");
      if (localStorage.studentCollegeDetails) {
        console.log("optimization is working");
        var bytes = base64.decode(localStorage.studentCollegeDetails);
        console.log("bytes", bytes);
        response = JSON.parse(bytes);
        console.log("response is", response);
      } else {
        response = await listColleges(token);
        var strObj = JSON.stringify(response);
        var encodedData = base64.encode(strObj);
        localStorage.setItem("studentCollegeDetails", encodedData);
        console.log(response);
      }
      var { data, status } = response;

      switch (status) {
        case 200:
        case 201:
        case 202: {
          if (data.constructor === [].constructor && data.length)
            data.sort((a, b) =>
              a.displayName > b.displayName
                ? 1
                : b.displayName > a.displayName
                ? -1
                : 0
            );
          setColleges(data);
          let params = new URL(document.location).searchParams;
          let college_id = params.get("collegeId");
          if (college_id) {
            const selectedCollege = data.find(
              (element) => element._id === college_id
            );
            console.log({ selectedCollege });
            var value = { ...{ college: selectedCollege }, ...values };
            setValues(value);
          }
          break;
        }
        case 400: {
          console.log({ data, status });
          console.log("handle 400 error");
          break;
        }
        case 401: {
          console.log("handle 401 error");
          break;
        }
        case 500: {
          console.log("handle 500 error");
          break;
        }
        default: {
          console.log("handle unknown error");
        }
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getCourses = async (_) => {
    try {
      console.log("listing courses");
      setLoading(true);
      if (values.college && values.college._id) {
        var token = await executeRecaptcha("list_courses");
        var response = await listCourses(token, values.college._id);
        console.log(response);
        var { data, status } = response;
        switch (status) {
          case 200:
          case 201:
          case 202: {
            if (data.constructor === [].constructor && data.length)
              data.sort((a, b) =>
                a.courseName > b.courseName
                  ? 1
                  : b.courseName > a.courseName
                  ? -1
                  : 0
              );
            setCourses(data);
            break;
          }
          case 400: {
            console.log("handle 400 error");
            break;
          }
          case 401: {
            console.log("handle 401 error");
            break;
          }
          case 500: {
            console.log("handle 500 error");
            break;
          }
          default: {
            console.log("handle unknown error");
          }
        }
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <div>
        <Typography component="div" variant="subtitle1" align="center">
          <Box className={classes.root1} p={1} mx="auto">
            Academic Details
          </Box>
          <br />
        </Typography>
      </div>

      <Container>
        <div className={classes.container}>
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <br />
          <Grid item xs={12}>
            {/* <ErrorBoundary> */}
            <FormControl>
              <InputLabel id="college">College*</InputLabel>
              <Select
                labelId="college"
                name="college"
                value={values.college ? values.college._id : ""}
                defaultValue={""}
                onChange={handleChange}
              >
                {colleges.map((college) => (
                  <MenuItem value={college._id} key={college._id}>
                    {college.displayName}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {"Select your college" || errors["collegeNameDetails"]}
              </FormHelperText>
            </FormControl>
            {/* </ErrorBoundary> */}
          </Grid>
          <Grid item xs={12}>
            {/* <ErrorBoundary> */}
            <FormControl>
              <InputLabel id="course">Course*</InputLabel>
              <Select
                labelId="course"
                id="course"
                name="course"
                value={values.course ? values.course._id : ""}
                defaultValue={
                  props.value[1]
                    ? props.value[1].courseNameDetails
                    : values.courseNameDetails
                }
                onChange={handleChange}
              >
                {courses.map((course) => (
                  <MenuItem value={course._id} key={course._id}>
                    {course.courseName}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {errors["courseNameDetails"] || "Select your course"}
              </FormHelperText>
            </FormControl>
            {/* </ErrorBoundary> */}
            <br />
            <br />
          </Grid>
          <br />
        </div>
        <br />
      </Container>
    </React.Fragment>
  );
}