
import React from 'react';
import instructor from './instructor/registerInstructor'
import app from './App'
import {  BrowserRouter as Router, Route ,Switch } from 'react-router-dom';
import homepage from './Homepage'
import collegeReg from './collegeAdmin/registerAdmin'
import errPage from "./ErrorBoundary";

  
  function server() {
      return (
          <div>
           <Router>
               <Switch>              
                      <Route exact path="/" component={homepage} />  
                <Route exact path = '/student' component = {app} />
                <Route exact path = '/instructor' component = {instructor} />
                <Route exact path = '/college' component = {collegeReg} />
                <Route path ="/err" component={errPage}/>

                <Route component={homepage} />

                </Switch>

            </Router>
          </div>
      )
  }
  
  export default server