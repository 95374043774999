import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Linkk from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Register from "./components/Register/Register";
var pjson = require("./../package.json");
var version = pjson.version;

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Linkk color="inherit" href="https://www.zlipp.in">
        Zlipp LMS
      </Linkk>{" "}
      {new Date().getFullYear()}-{"version :"} {version}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    offset: theme.mixins.toolbar,
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 1000,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    height: "100%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
}));

export default function Checkout() {
  const classes = useStyles();


  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="fixed" color="default" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            Zlipp LMS
          </Typography>
        </Toolbar>
      </AppBar>

      <main className={classes.layout}>
        <br />
        <br />

        <Paper className={classes.paper}>
          <br />
          <Typography component="h3" variant="h4" align="center">
            Hey!...Register as
          </Typography>
          <br />
          <br />

          <div className="container-fluid">
            <div className="row">
              <div className="col-4">
                <Register
                  url={require("./../src/assets/studenttc.svg")}
                  user={"Student"}
                  redirect={"/student"}
                />
              </div>
              <div className="col-4">
                <Register
                  url={require("./assets/adminc.svg")}
                  user={"College"}
                  redirect={"/college"}
                />
              </div>
              <div className="col-4">
                <Register
                  url={require("./assets/educatorc.svg")}
                  user={"Educator"}
                  redirect={"/instructor"}
                />
              </div>
            </div>
          </div>
        </Paper>
        <Copyright />
      </main>
    </React.Fragment>
  );
}
