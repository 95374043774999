import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Link } from "react-router-dom";
import  "../Register.css"

const useStyles = makeStyles((theme) => ({
  paper: {
    height: "100%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

const Register = (props) => {
  const classes = useStyles();

  const buttonStyle = {
    background: "#3f51b5",
    cursor: "pointer",
    padding: "4px 10px",
    fontSize: "0.8125rem",
    fontWeight: 500,
    lineHeight: 1.75,
    letterSpacing: "0.02857em",
    textTransform: "uppercase",
    boxShadow:"0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    minWidth: "64px",
    boxSizing: "border-box",
    transition:"background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontFamily:'"Roboto", "Helvetica", "Arial", "sans-serif"',
    border: "none",
  };

  return (
    <div>
      <Card className={classes.root} id="main_card">
        <CardContent>
          <Typography className="card_heading" variant="h6" component="h3" align="center">
            {props.user}
          </Typography>
          <img
            src={props.url}
            alt={props.user}
            style={{ width: "100%", height: "100%" }}
          />
          <br />
        </CardContent>
        <div
          className="center"
          style={{ textAlign: "center", paddingBottom: "1px" }}
        >
          <Link
            style={buttonStyle}
            className="btn btn-primary btn-sm card_button "
            size="small"
            variant="contained"
            to={props.redirect} id="card_button"
          >
            Register
          </Link>
        </div>
        <br />
      </Card>
    </div>
  );
};

export default Register;
